import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Consistent import for jwtDecode

loadFonts();

axios.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
        router.replace({ name: "login" });
        return Promise.reject("Token has expired");
      }
      request.headers["Authorization"] = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

createApp(App).use(router).use(vuetify).mount("#app");
