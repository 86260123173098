import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/Navbar.vue";
import RegisterPage from "@/views/Register.vue";
import LoginPage from "@/views/Login.vue";
import VideoStreamPage from "@/views/VideoStream.vue";
import KeyPage from "@/views/KeyPage.vue";
//import { jwtDecode } from "jwt-decode"; // Using a consistent function across the application

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/video-stream",
    name: "stream",
    component: VideoStreamPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/keys",
    name: "keys",
    component: KeyPage,
    meta: { requiresAuth: true },
  },
  { path: "/:catchAll(.*)", redirect: { name: "home" } }, // Fallback route
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//router.beforeEach((to, from, next) => {
//  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
//  const token = localStorage.getItem("token");
//
//  if (requiresAuth && !token) {
//    next({ name: "login" });
//  } else if (token) {
//    try {
//      const decodedToken = jwtDecode(token);
//      if (decodedToken.exp * 1000 < Date.now()) {
//        localStorage.removeItem("token");
//        next({ name: "login" });
//      } else {
//        next();
//      }
//    } catch (error) {
//      console.error("Token decoding error:", error);
//      localStorage.removeItem("token");
//      next({ name: "login" });
//    }
//  } else {
//    next();
//  }
//});

export default router;
