<template>
  <div class="video-container">
    <iframe
      width="560"
      height="315"
      :src="videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Example video URL: Embed URL for a YouTube video
      // Make sure to use the "embed" URL, not the standard watch URL
      videoUrl: "https://www.youtube.com/embed/ruvFi_cqO1Q?si=lLJPrgMsglJsQWDQ",
    };
  },
};
</script>

<style>
.video-container {
  /* You can adjust the styling as needed */
  text-align: center; /* Center the video iframe */
}
</style>
