<template>
  <v-app>
    <v-main>
      <AppNavbar />
      <router-view />
      <InstallBanner />
    </v-main>
  </v-app>
</template>

<script>
import AppNavbar from "@/views/Navbar.vue"; // Ensure the path is correct
import InstallBanner from "@/views/InstallBanner.vue"; // Ensure the path is correct

export default {
  name: "App",

  components: {
    AppNavbar, // Register the AppNavbar component
    InstallBanner, // Register the InstallBanner component
  },

  data: () => ({
    //
  }),
};
</script>

<style>
:root {
  --banner-height: 55px; /* Default value, can be updated dynamically */
}

.v-main {
  padding-top: var(--banner-height);
}
</style>
