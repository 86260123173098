<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-text>
            <v-form @submit.prevent="onSubmit">
              <v-text-field
                v-model="loginForm.username"
                label="Username"
                required
                type="username"
              ></v-text-field>
              <v-text-field
                v-model="loginForm.password"
                label="Password"
                required
                type="password"
              ></v-text-field>
              <v-card-actions>
                <v-btn type="submit" color="primary">Login</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginForm",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      deferredPrompt: null,
      errorMessage: null, // To display error messages from login attempts
    };
  },
  methods: {
    onSubmit() {
      axios
        .post("https://lockey.app/auth/login", this.loginForm)
        .then((response) => {
          // Handle the response from the server here
          // If login is successful, you might want to redirect or save the token
          console.log("Login successful:", response.data);
          localStorage.setItem("token", response.data.token);
          this.$router.push("/home"); // Redirect to dashboard or some other page
        })
        .catch((error) => {
          // If there is an error, we save it to display it on the form
          this.errorMessage =
            error.response.data.error || "An error occurred during login.";
          console.error("Error during login:", this.errorMessage);
        });
    },
  },
};
</script>
