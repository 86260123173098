<template>
  <div v-if="deferredPrompt && !isDismissed" class="install-banner">
    <v-btn icon @click="dismissBanner">
      <v-icon>mdi-close-thick</v-icon>
      <!-- Use an appropriate 'X' icon -->
    </v-btn>
    <span>Add lockey to home screen?</span>
    <v-btn @click="promptInstall" color="secondary">Install!</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deferredPrompt: null,
      isDismissed: false, // New data property to track if the banner is dismissed
    };
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
      this.deferredPrompt = e; // Stash the event so it can be triggered later.
    });
  },
  methods: {
    async promptInstall() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt(); // Show the install prompt
        const { outcome } = await this.deferredPrompt.userChoice; // Wait for the user to respond to the prompt
        if (outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        this.deferredPrompt = null;
      }
    },
    dismissBanner() {
      this.isDismissed = true; // Set the flag to true to hide the banner
      this.updateLayout(); // Call the method to update the layout
    },
    updateLayout() {
      // If the banner is dismissed, remove the padding from the top of the main content
      document.documentElement.style.setProperty("--banner-height", "0px");
    },
  },
};
</script>

<style scoped>
.install-banner {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #e0e0e0;
  padding: 10px;
  text-align: center;
  z-index: 1000;
  display: flex;
  justify-content: space-between; /* Align the close button to the right */
  align-items: center;
}

/* Style for the close button if necessary */
.close-button {
  margin-left: auto; /* Push the button to the right side of the banner */
}
</style>
