<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-text>
            <v-form ref="form" @submit.prevent="onSubmit" v-model="valid">
              <v-text-field
                v-model="RegistrationForm.username"
                :rules="usernameRules"
                label="Username"
                required
              ></v-text-field>
              <v-text-field
                v-model="RegistrationForm.password"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                label="Password"
                required
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="toggleShowPassword"
              ></v-text-field>
              <v-card-actions>
                <v-btn :disabled="!valid" type="submit" color="primary"
                  >Register</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "RegistrationForm",
  data() {
    return {
      RegistrationForm: {
        username: "",
        password: "",
      },
      valid: false,
      showPassword: false,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be at least 8 characters", // example rule
      ],
      usernameRules: [(v) => !!v || "Username is required"],
    };
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        // This ensures the form is only submitted if it is valid
        axios
          .post("https://lockey.app/register", this.RegistrationForm)
          .then((response) => {
            console.log("Registration successful:", response.data);
            this.$router.push("/home");
          })
          .catch((error) => {
            this.errorMessage =
              error.response.data.error ||
              "An error occurred during registration.";
            console.error("Error during Registration:", this.errorMessage);
          });
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
