<template>
  <v-card class="mx-auto" max-width="344" title="Key Registration">
    <v-container>
      <v-text-field
        prepend-icon="mdi-cellphone-nfc"
        :color="nfcSupportColor"
        v-model="UID"
        label="UID"
        variant="underlined"
        @click="startNfcScan"
      ></v-text-field>

      <v-text-field
        v-model="name"
        color="primary"
        label="door"
        variant="underlined"
      ></v-text-field>

      <v-text-field
        v-model="password"
        color="primary"
        label="Password"
        placeholder="Enter your password"
        variant="underlined"
      ></v-text-field>

      <v-checkbox
        v-model="terms"
        color="secondary"
        label="I agree to site terms and conditions"
      ></v-checkbox>
    </v-container>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success">
        Complete Registration
        <v-icon icon="mdi-chevron-right" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* global NDEFReader */
export default {
  data: () => ({
    UID: null,
    name: null,
    password: null,
    terms: false,
  }),
  computed: {
    nfcSupportColor() {
      return "NDEFReader" in window ? "blue" : "grey";
    },
  },
  methods: {
    async startNfcScan() {
      if ("NDEFReader" in window) {
        try {
          const ndef = new NDEFReader();
          await ndef.scan();
          console.log("NFC scan started successfully.");
          ndef.onreadingerror = () => {
            console.error("Error reading the NFC tag.");
          };
          ndef.onreading = (event) => {
            console.log("NDEF message read.");
            this.UID = event.serialNumber; // Directly assign the read UID to the data property
          };
        } catch (error) {
          console.error(`Error starting NFC scan: ${error}.`);
        }
      } else {
        console.log("Web NFC is not supported by this browser.");
      }
    },
  },
};
</script>
